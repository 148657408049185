
























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SubscriptionPrintMode extends Vue {
  @Prop({ required: true }) subscription!: any
  @Prop({ type: Boolean }) print!: boolean
}
