























import { Component, Vue, Prop } from 'vue-property-decorator'
import axios, { AxiosResponse } from 'axios'
import camelCaseKeys from 'camelcase-keys'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import SubscriptionPrintMode from '@/partials/components/Events/SubscriptionPrintMode.vue'

@Component({
  components: {
    GenericModalDelete,
    SubscriptionPrintMode
  }
})
export default class PrintSubscriptionOperation extends Vue {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionId!: string
  subscription = {
    nameFull: '',
    nameCred: '',
    sex: '',
    email: '',
    cpf: '',
    rg: '',
    crm: '',
    teot: '',
    address: '',
    cep: '',
    uf: '',
    phone: '',
    mobile: '',
    country: '',
    institution: '',
    notes: '',
    subscriptionType: {
      typePrice: ''
    },
    paid: 0
  }

  responseError = ''

  canPrint = false

  submit () {
    this.canPrint = false
    this.$emit('submitting')
    axios.get(`/event/${this.eventId}/subscription/${this.subscriptionId}/print`)
      .then(response => {
        this.canPrint = true
        this.subscription = camelCaseKeys(response.data.data, { deep: true })
        Vue.nextTick(() => {
          print()
        })
        this.$emit('submitted')
      })
      .catch(err => this.handleSubmitError(err.response))
      .finally(() => {
        this.canPrint = false
        this.$emit('submitted')
      })
  }

  handleSubmitError (response: AxiosResponse) {
    if (response?.data?.data) {
      this.responseError = response.data.data
    }
  }
}
