

























































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
// import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import EventOrganizersList from '@/partials/components/Events/Organizers/list.vue'

@Component({
  components: {
    EventOrganizersList,
    EventUserDropdown,
    // GenericModalForm,
    PasswordConfirmField,
    ValidationProvider
  }
})
export default class EventConsumeVoucher extends Mixins(PermissionsMixin, ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionId!: string
  modalIsVisible = false

  get isOrganizer () {
    return this.hasEventPermission(this.eventId, this.permissions.EVENTREPORT.READ)
  }

  // form: Record<string, any> = {
  //   eventUserId: '',
  //   confirm: '',
  //   errors: {
  //     eventUserId: '',
  //     confirm: ''
  //   }
  // }

  // postSubmit (response: string) {
  //   this.modalIsVisible = false
  //   this.$emit('submitted', response)

  //   const flashMessage: flashMessage = {
  //     message: 'Inscrição transferida',
  //     type: 'success'
  //   }

  //   this.setFlashMessage(flashMessage)
  // }

  // @Watch('modalIsVisible')
  // onModalIsVisibleChange () {
  //   if (!this.modalIsVisible) {
  //     this.form = {
  //       paid: 0,
  //       confirm: '',
  //       errors: {
  //         paid: '',
  //         confirm: ''
  //       }
  //     }
  //   }
  // }
}
