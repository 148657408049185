



































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import camelCaseKeys from 'camelcase-keys'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import MoneyInput from '@/partials/forms/components/MoneyInput.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'
import EventOrganizersList from '@/partials/components/Events/Organizers/list.vue'
import { AxiosResponse } from 'axios'

@Component({
  components: {
    EventOrganizersList,
    GenericModalForm,
    MoneyInput,
    PasswordConfirmField,
    ValidationProvider
  }
})
export default class EventSubscriptionCancel extends Mixins(ValidatorConfigMixin, FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: Number }) subscriptionPaid!: number
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionId!: string
  @Prop({ type: Boolean }) isEventShow!: string
  modalIsVisible = false

  form: Record<string, any> = {
    paid: 0,
    confirm: '',
    errors: {
      paid: '',
      confirm: ''
    }
  }

  postSubmit (response: AxiosResponse) {
    this.modalIsVisible = false
    this.$emit('submitted', camelCaseKeys(response, { deep: true }))

    const flashMessage: flashMessage = {
      message: 'Inscrição cancelada com sucesso.',
      type: 'success'
    }
    this.setFlashMessage(flashMessage)
  }

  @Watch('modalIsVisible')
  onModalIsVisibleChange () {
    if (!this.modalIsVisible) {
      this.form = {
        paid: this.subscriptionPaid,
        confirm: '',
        errors: {
          paid: '',
          confirm: ''
        }
      }
    }
  }

  @Watch('subscriptionPaid', { immediate: true })
  onSubscriptionPaidChange (newValue: number) {
    this.form.paid = newValue
  }
}
