











import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

@Component
export default class EventOrganizersList extends Vue {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) text!: string
  organizers = []

  created () {
    axios.get(`/event/${this.eventId}/users/organizers`)
      .then(response => {
        this.organizers = camelCaseKeys(response.data.data, { deep: true })
      })
  }
}
