














import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'
import { Money } from 'v-money'

@Component({
  components: {
    Money
  }
})
export default class MoneyInput extends Vue {
  @ModelSync('value', 'input', { type: Number }) inputField!: number
  @Prop({ type: String }) label!: string
  @Prop({ type: String }) helperText!: string
  @Prop({ type: Boolean }) disabled!: boolean

  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false
  }
}
