












import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'

// type UserDropdown = [ id: string; name: string ]

@Component
export default class EventUserDropdown extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String, required: true }) type!: EventPerson
  @Prop({ type: String, required: true }) eventId!: string
  @Prop({ type: String }) invalidMessage!: string
  @Prop({ type: Boolean }) disabled!: boolean
  comboModel = ''
  users: Record<string, any> = []

  async mounted () {
    await axios.get(this.requestUrl)
      .then((response) => {
        const users = response.data.data

        this.users = users.map((eventUser: any) => {
          const option = {
            value: eventUser.id,
            name: eventUser.user.name,
            label: eventUser.user.name
          }
          return option
        })

        this.$nextTick(() => {
          this.comboModel = this.inputField
        })
      })
  }

  @Watch('comboModel')
  onComboModelChange () {
    this.inputField = this.comboModel
    this.$emit('change', this.users.find((userEvent: any) => userEvent.value === this.comboModel)?.name)
  }

  get requestUrl () {
    const dict = {
      speaker: 'speakers',
      subscriber: 'subscribers',
      sponsor: 'sponsors',
      studyReviewer: 'study-reviewers',
      organizer: 'organizers',
      staff: 'staff'
    }
    return `/event/${this.eventId}/users/${dict[this.type]}/dropdown`
  }

  get comboBoxTitle () {
    const dict = {
      speaker: 'Palestrante',
      subscriber: 'Inscrito',
      sponsor: 'Patrocinador',
      studyReviewer: 'Avaliador',
      organizer: 'Organizador',
      staff: 'Membro da Equipe'
    }

    return dict[this.type]
  }
}
